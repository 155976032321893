import axios from "axios";
import { showNotification } from "./ImportantComponents";

const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("adminToken");

const getAuthHeaders = () => {
  const token = localStorage.getItem("adminToken");
  if (!token) return {};
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const loginHandler = async ({
  payload,
  navigate,
  isLoading,
  setIsLoading,
}) => {
  localStorage.clear();
  try {
    if (!payload.email)
      return showNotification({ type: "error", message: "Email is required" });
    if (!payload.password)
      return showNotification({
        type: "error",
        message: "Password is required",
      });

    setIsLoading(true);
    const res = await axios.post(`${baseUrl}admin/signin`, payload);
    localStorage.setItem("adminToken", res?.data?.accessToken);
    showNotification({ type: "success", message: "Login successful" });
    setIsLoading(false);
    window.location.href = "/dashboard";
    return;
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};

export const forgotPasswordHandler = async ({
  payload,
  navigate,
  nextPage,
  setIsLoading,
}) => {
  const forgotPassword = localStorage.getItem("forgotPassword");
  const forgotEmail = localStorage.getItem("forgotEmail");
  const forgotMobile = localStorage.getItem("forgotMobile");
  const forgotOtpVerification = localStorage.getItem("forgotOtpVerification");
  if (payload.password) {
    localStorage.setItem("forgotPassword", payload.password);
    nextPage();
  }
  if (payload.email) {
    try {
      setIsLoading(true);
      const res = await axios.post(`${baseUrl}admin/forgetPassword`, payload);
      localStorage.setItem("forgotEmail", payload.email);
      localStorage.setItem("userId", res?.data?.data?._id);
      nextPage();
      showNotification({ type: "success", message: res?.data?.message });
      showNotification({ type: "success", message: res?.data?.data?.otp });
      setIsLoading(false);
    } catch (error) {
      showNotification({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
      setIsLoading(false);
      return;
    }
  }
};

export const verifyOtpMain = async ({
  payload,
  navigate,
  nextPage,
  setIsLoading,
}) => {
  try {
    setIsLoading(true);
    const res = await axios.post(
      `${baseUrl}admin/changePassword/${localStorage.getItem("userId")}`,
      payload
    );
    localStorage.setItem("forgotOtpVerification", payload.otp);
    nextPage();
    showNotification({ type: "success", message: res?.data?.message });
    setIsLoading(false);
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong",
    });
    setIsLoading(false);
    return;
  }
};

export const getAllData = async ({ url, setData, setIsLoading, params }) => {
  try {
    if (setIsLoading) setIsLoading(true);
    const res = await axios.get(`${baseUrl}${url}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(res?.data);
    if (setIsLoading) setIsLoading(false);
  } catch (error) {
    setData([]);
    if (setIsLoading) setIsLoading(false);
    showNotification({
      type: "error",
      message: error?.response?.data?.message || "An error occurred",
    });
  }
};

export const postData = async ({
  url,
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  try {
    setIsLoading(true);
    const res = await axios.post(`${baseUrl}${url}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (additionalFunc) additionalFunc();
    showNotification({ type: "success", message: res?.data?.message });
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.log(error);
    showNotification({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong",
    });
  }
};

export const putData = async ({
  url,
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  try {
    console.log("payload", payload);

    setIsLoading(true);
    const res = await axios.put(
      `${baseUrl}${url}`,
      payload,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showNotification({ type: "success", message: res?.data?.message });
    if (additionalFunc) additionalFunc();
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
    showNotification({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong",
    });
  }
};

export const getSingleData = async ({ url, setData, setIsLoading }) => {
  try {
    setIsLoading(true);

    const res = await axios.get(`${baseUrl}${url}`, ...getAuthHeaders());
    setData(res?.data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);

    showNotification({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong",
    });
  }
};

export const getDataWithPagination = async ({
  url,
  setData,
  setIsLoading,
  page,
}) => {
  try {
    setIsLoading(true);
    const res = await axios.get(
      `${baseUrl}${url}?page=${page}`,
      ...getAuthHeaders()
    );
    setData(res?.data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
  }
};

export const formatDate = (date) => {
  const newDate = date?.split("T")?.[0]?.split("-")?.reverse()?.join("-");
  return newDate;
};

export const deleteData = async ({ url, setIsLoading, additionalFun }) => {
  try {
    setIsLoading(true);
    const res = await axios.delete(`${baseUrl}${url}`, { ...getAuthHeaders() });

    showNotification({ type: "success", message: res?.data?.message });

    setIsLoading(false);

    if (additionalFun) additionalFun();
  } catch (error) {
    setIsLoading(false);

    console.log(error);

    showNotification({
      type: "error",
      message: error?.response?.data?.message || "An error occurred",
    });
  }
};
