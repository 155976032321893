import { useEffect, useState } from "react";
import "./TotalUsers.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import img from "../../Img/img3.png";
import img1 from "../../Img/img4.png";
import { formatDate, getAllData } from "../../Api/api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Iframe from "react-iframe";

const UserDetail = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [landlineNumber, setLandlineNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [residenceAddress, setResidenceAddress] = useState("");
  const [firstLineAddress, setFirstLineAddress] = useState("");
  const [secondLineAddress, setSecondLineAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [shopTurnOver, setShopTurnOver] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [shopName, setShopName] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [totalStaff, setTotalStaff] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [shopLocation, setShopLocation] = useState("");

  const [profilePic, setProfilePic] = useState(null);
  const [shopImage, setShopImage] = useState(null);
  const [adhaarImage, setAdhaarImage] = useState(null);
  const [gstCertificate, setGstCertificate] = useState(null);
  const [panCardImage, setPanCardImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    getAllData({
      url: `admin/getUserById/${params.id}`,
      setData: setData,
      setIsLoading: setIsLoading,
    });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.data?.user) {
      setUserDetail(data?.data);
    }
  }, [data]);

  const [step1, setStep1] = useState(0);

  const [data1, setData1] = useState([]);
  const [customerTransaction, setCustomerTransaction] = useState([]);
  const [shopTransaction, setShopTransaction] = useState([]);
  const [shopKeeperRating, setShopKeeperRating] = useState([]);
  const [userDetail1, setUserDetail1] = useState({});
  const [isLoading1, setIsLoading1] = useState(false);

  const getData1 = () => {
    getAllData({
      url: `admin/getShopkeeperById/${params.id}`,
      setData: setData1,
      setIsLoading: setIsLoading1,
    });

    if (step1 === 1) {
      getAllData({
        url: `admin/getPayment?shopkeeperId=${params.id}`,
        setData: setCustomerTransaction,
        setIsLoading: setIsLoading,
      });
    } else if (step1 === 2) {
      getAllData({
        url: `admin/getPayment?shopkeeperId=${params.id}`,
        setData: setShopTransaction,
        setIsLoading: setIsLoading,
      });
    } else if (step1 === 3) {
      getAllData({
        url: `admin/getRatingShopkeeperById/${params.id}`,
        setData: setShopKeeperRating,
        setIsLoading: setIsLoading,
      });
    }
  };

  useEffect(() => {
    getData1();
  }, [step1]);

  useEffect(() => {
    if (data?.data) {
      setUserDetail(data?.data);
    }
  }, [data]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="kyc7">
        <p>
          Holder Name: John Due
          <br />
          Account no. :- 1234567896541
          <br />
          IFSC Code:- 145258622
        </p>
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className="userdetails">
        <div className="totalusers5">
          <IoIosArrowBack
            color="#202224"
            size={25}
            onClick={() => navigate(-1)}
          />
          <h6>Customer Details</h6>
        </div>
        <div className="userdetails1">
          <div className="userdetails2">
            <div className="userdetails3">
              <div className="userdetails4">
                <img
                  src={
                    userDetail?.user?.profilePic ||
                    userDetail?.loanApplication?.customerPhoto ||
                    img
                  }
                  alt=""
                />
              </div>
              <div className="userdetails5">
                <h6>
                  {(userDetail?.user?.firstName || "") +
                    " " +
                    (userDetail?.user?.lastName || "")}
                </h6>
                <p>{userDetail?.user?.status}</p>
              </div>
            </div>
            <div className="userdetails6">
              {/* <button>Download Report</button> */}
            </div>
          </div>

          <div className="userdetails7">
            <div className="userdetails8">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                name="firstName"
                value={userDetail?.user?.firstName}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={userDetail?.user?.lastName}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Father's Name</label>
              <input
                type="text"
                name="lastName"
                value={userDetail?.user?.fatherName}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Mobile Number</label>
              <input
                type="text"
                name="mobileNumber"
                value={userDetail?.user?.mobileNumber}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name="email"
                value={userDetail?.user?.email}
                disabled
              />
            </div>

            <div className="userdetails8">
              <label htmlFor="">Aadhar Card Number</label>
              <input
                type="text"
                name="aadharCard"
                value={userDetail?.user?.aadharCard}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">PAN Number</label>
              <input
                type="text"
                name="panNumber"
                value={userDetail?.user?.panNumber}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">GST No.</label>
              <input
                type="text"
                name="gstNo"
                value={userDetail?.user?.gstNo}
                disabled
              />
            </div>
            {/* <div className="userdetails8">
              <label htmlFor="">Bank Name</label>
              <input
                type="text"
                name="landlineNumber"
                value={userDetail?.user?.bankName}
                disabled
              />
            </div> */}
            <div className="userdetails8">
              <label htmlFor="">Account Holder Name</label>
              <input
                type="text"
                name="acc holder name"
                value={userDetail?.user?.accountHolderName}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Bank Account Number</label>
              <input
                type="text"
                name="Bank Account Number"
                value={userDetail?.user?.bankAccountNumber}
                disabled
              />
            </div>

            <div className="userdetails8">
              <label htmlFor="">Residence Address</label>
              <input
                type="text"
                name="residenceAddress"
                value={userDetail?.user?.residenceAddress}
                disabled
              />
            </div>

            <div className="userdetails8">
              <label htmlFor="">District</label>
              <input
                type="text"
                name="district"
                value={userDetail?.user?.district}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Pin Code</label>
              <input
                type="text"
                name="pinCode"
                value={userDetail?.user?.pinCode}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Country</label>
              <input
                type="text"
                name="country"
                value={userDetail?.user?.country}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">State</label>
              <input
                type="text"
                name="state"
                value={userDetail?.user?.state}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Shop Turn Over</label>
              <input
                type="text"
                name="shopTurnOver"
                value={userDetail?.user?.shopTurnOver}
                disabled
              />
            </div>

            <div className="userdetails8">
              <label htmlFor="">Shop Name</label>
              <input
                type="text"
                name="shopName"
                value={userDetail?.user?.shopName}
                disabled
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Shop Location</label>
              <input
                type="text"
                name="shopLocation"
                value={userDetail?.user?.shopLocation}
                disabled
              />
            </div>

            <div className="userdetails8">
              <label htmlFor="">Total Staff</label>
              <input
                type="text"
                name="totalStaff"
                value={userDetail?.user?.totalStaff}
                disabled
              />
            </div>
          </div>

          <div className="userdetails9">
            <div className="userdetails10">
              {/* <div
                className={step === 0 ? "userdetails11" : "userdetails12"}
                onClick={() => setStep(0)}
              >
                <p>Loan Account Summary</p>
                {step == 0 && <img src={img1} alt="" />}
              </div>
              <div
                className={step === 1 ? "userdetails11" : "userdetails12"}
                onClick={() => setStep(1)}
              >
                <p>Transaction Summary</p>
                {step == 1 && <img src={img1} alt="" />}
              </div> */}
              <div
                className={step === 2 ? "userdetails11" : "userdetails12"}
                onClick={() => setStep(2)}
              >
                <p>Loan Related Document</p>
                {step == 2 && <img src={img1} alt="" />}
              </div>
            </div>
            {step === 0 ? (
              <div className="userdetails13">
                <div className="totalusers9">
                  <div className="totalusers10">
                    <table>
                      <thead>
                        <tr>
                          <th>Due Date</th>
                          <th>Type</th>
                          <th>EMI Amount</th>
                          <th>Interest Amount</th>
                          <th>Closing EMI Amount</th>
                          <th>Payment Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userDetail?.loanInstallment?.length > 0 &&
                          userDetail?.loanInstallment?.map((data) => (
                            <tr key={data._id}>
                              <td>{formatDate(data.dueDate)}</td>
                              <td>{data.type}</td>
                              <td>{data.emiAmount}</td>
                              <td>{data.interestAmount}</td>
                              <td>{data.closingEmiAmount}</td>
                              <td
                                style={{
                                  color:
                                    data.paymentStatus === "pending"
                                      ? "#F44336"
                                      : "#202224",
                                }}
                              >
                                {data.paymentStatus}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : step === 1 ? (
              <div className="userdetails13">
                <div className="totalusers9">
                  <div className="totalusers10">
                    <table>
                      <thead>
                        <tr>
                          <th>Due Date</th>
                          <th>Type</th>
                          <th>EMI Amount</th>
                          <th>Transaction ID</th>
                          <th>Payment Mode</th>
                          <th>Payment Date</th>
                          <th>Payment Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userDetail?.transaction?.length > 0 &&
                          userDetail?.transaction?.map((data) => (
                            <tr key={data.id}>
                              <td>{formatDate(data.dueDate)}</td>
                              <td>{data.type}</td>
                              <td>{data.emiAmount}</td>
                              <td>{data.transactionId}</td>
                              <td>{data.paymentMode}</td>
                              <td>{formatDate(data.date)}</td>
                              <td style={{ color: "#09B006" }}>
                                {data.paymentStatus}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : step === 2 ? (
              <>
                <div className="userdetails13">
                  <div className="userdetails14">
                    <Iframe
                      url={userDetail?.user?.aadharCardImage}
                      width="100%"
                      height="350px"
                      allowFullScreen
                      id=""
                      className=""
                      display="block"
                      position="relative"
                    />
                    <Iframe
                      url={userDetail?.user?.panCardImage}
                      width="100%"
                      height="350px"
                      allowFullScreen
                      id=""
                      className=""
                      display="block"
                      position="relative"
                    />
                    <Iframe
                      url={userDetail?.user?.gstCertificate}
                      width="100%"
                      height="350px"
                      allowFullScreen
                      id=""
                      className=""
                      display="block"
                      position="relative"
                    />

                    <img
                      src={userDetail?.user?.shopImage}
                      alt=""
                      className="userdetails15"
                      style={{ width: "100%", height: "350px" }}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {data?.data?.user?.userType !== "User" && (
            <div className="userdetails9">
              <div className="userdetails10">
                <div
                  className={step1 === 0 ? "userdetails11" : "userdetails12"}
                  onClick={() => setStep1(0)}
                >
                  <p>Overview</p>
                  {step1 == 0 && <img src={img1} alt="" />}
                </div>
                <div
                  className={step1 === 1 ? "userdetails11" : "userdetails12"}
                  onClick={() => setStep1(1)}
                >
                  <p> Transaction History</p>
                  {step1 == 1 && <img src={img1} alt="" />}
                </div>
                {/* <div
                className={step1 === 2 ? "userdetails11" : "userdetails12"}
                onClick={() => setStep1(2)}
              >
                <p>Shop Transaction History</p>
                {step1 == 2 && <img src={img1} alt="" />}
              </div> */}
                <div
                  className={step1 === 3 ? "userdetails11" : "userdetails12"}
                  onClick={() => setStep1(3)}
                >
                  <p>Customer Feedback</p>
                  {step1 == 3 && <img src={img1} alt="" />}
                </div>
              </div>

              {step1 === 0 ? (
                <div className="userdetails13">
                  <div className="wallet1">
                    <div className="wallet2">
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#CEE2F3" }}
                      >
                        <p>Total Mobile DP Price</p>
                        <p>{userDetail?.user?.totalMobileDpPrice}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#EBD0DC" }}
                      >
                        <p>Total EMI Collection</p>
                        <p>{userDetail?.user?.totalEmiCollection}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#EBD0DC" }}
                      >
                        <p>Total Penalty Collection</p>
                        <p>{userDetail?.user?.totalPenaltyCollection}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#EBD0DC" }}
                      >
                        <p>Total Down Payment Collection</p>
                        <p>{userDetail?.user?.totalDownPayment}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#B6D7A8" }}
                      >
                        <p>Profit Price</p>
                        <p>{userDetail?.user?.profitPrice?.toFixed(2)}</p>
                      </div>
                    </div>
                    <div className="wallet2">
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#F9CA9C" }}
                      >
                        <p>Total Loan Amount</p>
                        <p>{userDetail?.user?.totalLoanAmount}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#E9B8AE" }}
                      >
                        <p>Pending Collection Amount</p>
                        <p>{userDetail?.user?.pendingEmiCollection}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#D1E0E3" }}
                      >
                        <p>Monthly EMI</p>
                        <p>{userDetail?.user?.monthlyEmi?.toFixed(2)}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#E5EDFA" }}
                      >
                        <p>Other EMI / Penalty Pending</p>
                        <p>{userDetail?.user?.otherEmiPenaltyPending}</p>
                      </div>
                      <div
                        className="wallet3"
                        style={{ backgroundColor: "#FFFFFF" }}
                      >
                        <p>Loss Price</p>
                        <p>{userDetail?.user?.lossPrice}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : step1 === 1 ? (
                <div className="userdetails13">
                  <div className="totalusers9">
                    <div className="totalusers10">
                      <table>
                        <thead>
                          <tr>
                            <th>Date/Time</th>
                            <th>Loan ID</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                            <th>Pay Mode</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerTransaction?.data?.map((data) => (
                            <tr key={data.id}>
                              <td>{data.DateTime}</td>
                              <td>{data.LoanID}</td>
                              <td>{data.TransactionID}</td>
                              <td>{data.Amount}</td>
                              <td>{data.PayMode}</td>
                              <td style={{ color: "#09B006" }}>
                                {data.Status}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : step1 === 2 ? (
                <div className="userdetails13">
                  <div className="totalusers9">
                    <div className="totalusers10">
                      <table>
                        <thead>
                          <tr>
                            <th>Date/Time</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                            <th>Pay Mode</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shopTransaction?.data?.map((data) => (
                            <tr key={data.id}>
                              <td>{data.DateTime}</td>
                              <td>{data.TransactionID}</td>
                              <td>{data.Amount}</td>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <p>{data.PaymentMode}</p>
                                </OverlayTrigger>
                              </td>
                              <td style={{ color: "#09B006" }}>
                                {data.PaymentStatus}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : step1 === 3 ? (
                <>
                  <div className="userdetails13">
                    <div className="totalusers9">
                      <div className="totalusers10">
                        <table>
                          <thead>
                            <tr>
                              <th>Customer Name</th>
                              <th>Date</th>
                              <th>Rating</th>
                              <th>Comment</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {setShopKeeperRating?.data?.map((data) => (
                              <tr key={data.id}>
                                <td>{data.CustomerName}</td>
                                <td>{data.Date}</td>
                                <td>{data.Rating}</td>
                                <td>{data.Comment}</td>
                                <td style={{ color: "#09B006" }}>
                                  {data.Status}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HOC(UserDetail);
