import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";

import { LuUser2 } from "react-icons/lu";
import { IoArrowForward } from "react-icons/io5";
import { verifyOtpMain } from "../../Api/api";

const Verificationcode = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      event.preventDefault();
      event.target.previousSibling.focus();
    }
  };

  const handleSubmit = () => {
    const otpCode = otp.join("");
    console.log("OTP entered: ", otpCode);
    verifyOtpMain({
      payload: {
        otp: otpCode,
        newPassword: localStorage.getItem("forgotPassword"),
        confirmPassword: localStorage.getItem("forgotPassword"),
      },
      userId: localStorage.getItem("forgotEmail"),
      navigate,
      nextPage: () => navigate("/"),
      setIsLoading,
    });
  };
  return (
    <>
      <div className="login">
        <div className="login1">
          <div className="login2">
            <div className="login33">
              <div className="login3">
                <LuUser2 color="#1B85F3" size={30} />
              </div>
            </div>

            <div className="login4">
              <h5>Send verification code</h5>
              <p>
                enter verification code sent to your registered mobile or email.
              </p>
            </div>

            <div className="otp-container">
              {otp.map((data, index) => {
                return (
                  <input
                    className="otp-input"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    placeholder="0"
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            <div className="login9">
              <button onClick={() => handleSubmit()}>
                NEXT <IoArrowForward size={25} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verificationcode;
