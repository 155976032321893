import "./TotalUsers.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { MdDelete, MdRemoveRedEye } from "react-icons/md";
import { useEffect, useState } from "react";
import { deleteData, getAllData } from "../../Api/api";
import { FaRegEdit } from "react-icons/fa";

const TotalUsers = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(data?.data?.totalDocs || 0);
  const [currentPage, setCurrentPage] = useState(data?.data?.page || 0);
  const [totalPage, setTotalPage] = useState(data?.data?.totalPages || 0);
  const [search, setSearch] = useState("");
  const [filterUsers, setFilterUsers] = useState("");

  const getData = () => {
    const params = {};
    if (search) params.search = search;
    if (filterUsers) params.kycVerified = filterUsers;
    getAllData({
      url: `admin/getShopkeeper`,
      setData: setData,
      setIsLoading: setIsLoading,
      params: params,
    });
  };
  useEffect(() => {
    getData();
  }, [search, filterUsers]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const handleDelete = (id) => {
    console.log(id);
    const additionalFun = () => {
      getData();
    };
    deleteData({
      url: `shopkeeper/removeStaff/${id}`,
      setIsLoading: setIsLoading,
      getData: getData,
      additionalFun: additionalFun,
    });
  };

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers2">
            <div className="totalusers3" onClick={() => navigate("/createid")}>
              <h6>Create ID</h6>
            </div>
            <div style={{ marginLeft: "20px" }} className="totalusers3">
              <h6>Total User: {data?.data?.totalDocs || 0}</h6>
            </div>
          </div>

          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Total Shop Users</h6>
            </div>
            <div className="totalusers6">
              <div className="totalusers7">
                <IoIosSearch color="#D5D5D5" size={20} />
                <input
                  type="search"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {/* <div className="totalusers8">
                <select
                  onChange={(e) => setFilterUsers(e.target.value)}
                  value={filterUsers}
                  name=""
                  id=""
                >
                  <option value="">Filter</option>
                  <option value="">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                </select>
              </div> */}
            </div>
          </div>

          <div className="totalusers9">
            <div className="totalusers10">
              <table>
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>Shop ID</th>
                    <th>Shop Name</th>
                    <th>Owner Name</th>
                    <th>Mobile No.</th>
                    <th>Email</th>
                    {/* <th>Payment Status</th> */}
                    <th>KYC Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {data?.data?.docs.map((data, index) => (
                    <tr key={data._id}>
                      <td>{data?.Id}</td>
                      <td>{data?.shopName}</td>
                      <td>{data?.firstName + " " + data?.lastName}</td>
                      <td>{data.mobileNumber}</td>
                      <td>{data?.email}</td>
                      {/* <td>{data?.isVerified ? "Yes" : "No"}</td> */}
                      <td>{data?.kycVerified ? "Yes" : "No"}</td>

                      <td className="totalusers11">
                        <div style={{ display: "flex", gap: "10px" }}>
                          <MdRemoveRedEye
                            color="#1C1B1F"
                            size={20}
                            onClick={() => navigate(`/user-detail/${data._id}`)}
                          />

                          <FaRegEdit
                            onClick={() =>
                              navigate(`/edit-user-detail/${data._id}`)
                            }
                            color="#1C1B1F"
                            size={20}
                          />

                          <MdDelete
                            color="#1C1B1F"
                            size={20}
                            onClick={() => handleDelete(data._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageClick}
            containerClassName={paginationStyles.pagination}
            previousLinkClassName={paginationStyles.paginationLink}
            nextLinkClassName={paginationStyles.paginationLink}
            disabledClassName={paginationStyles.paginationDisabled}
            activeClassName={paginationStyles.paginationActive}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        </div> */}
      </div>
    </>
  );
};

export default HOC(TotalUsers);
